export const WSLabel = ({ htmlFor, label }: { htmlFor: string; label: string }) => {
  return (
    <label
      htmlFor={htmlFor}
      className="block mt-2 mb-1 text-xs font-medium text-neutral-textSubtle "
    >
      {label}
    </label>
  );
};

export default WSLabel;
