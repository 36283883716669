/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";

import { Consumer } from "../utils";

interface ITdInnerProps {
  columnkey?: number;
  narrowHeaders: Record<number, any>;
  columns: Record<number, any>;
  children: ReactNode;
}

const TdInner = (props: ITdInnerProps) => {
  const { columns, columnkey = 0, ...rest } = props;
  return (
    <td
      data-testid="td"
      className={`pivoted whitespace-no-wrap text-md leading-5 text-neutral-text`}
      {...rest}
    >
      <div data-testid="td-before" className="tdBefore">
        {columns[columnkey].Header}
      </div>
      {props.children ?? <div>&nbsp;</div>}
    </td>
  );
};

export type ITdProps = Omit<ITdInnerProps, "narrowHeaders">;

export const Td = (props: ITdProps) => (
  <Consumer>{(headers) => <TdInner {...props} narrowHeaders={headers} />}</Consumer>
);
