import { ReactNode } from "react";
import { usePopperTooltip } from "react-popper-tooltip";

interface WSTooltipProps {
  text: string;
  children: ReactNode;
}

export const WSTooltip = ({ text, children }: WSTooltipProps) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } = usePopperTooltip({
    placement: "auto-start",
  });

  return (
    <>
      <div ref={setTriggerRef}>{children}</div>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: "tooltip-container" })}
          className="z-30 px-2 py-1 rounded-lg shadow-md bg-neutral-background text-neutral-textSubtle"
        >
          {text}
          {/* <div {...getArrowProps()} /> */}
        </div>
      )}
    </>
  );
};
