import { XIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";

interface IFile {
  file: string | ArrayBuffer | null;
  fileName: string;
  id: number;
}

type InputProps = {
  onChange: (files: IFile[]) => void;
  values: IFile[];
  maxNumberOfFiles: number;
};

export const WSDropzone = ({ onChange, values, maxNumberOfFiles = 1 }: InputProps) => {
  const [filesLocal, setFilesLocal] = useState<IFile[]>([]);
  const [fileToAdd, setFileToAdd] = useState<IFile | null>(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setFilesLocal(values);
    if (filesLocal.length >= maxNumberOfFiles) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [values]);

  useEffect(() => {
    if (fileToAdd) {
      const filesDataNew = [...filesLocal];
      filesDataNew.push(fileToAdd);
      setFilesLocal(filesDataNew);
      onChange(filesDataNew);
    }
  }, [fileToAdd]);

  useEffect(() => {
    if (filesLocal.length >= maxNumberOfFiles) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [filesLocal]);

  const readmultIFile = (files: File[]) => {
    setupReader(files, 0);
  };

  const setupReader = (files: File[], i: number) => {
    const file = files[i];
    if (file) {
      const name = file.name;
      const reader = new FileReader();
      reader.onload = function (e) {
        readerLoaded(e, files, i, name);
      };
      // reader.readAsDataURL(file);
      reader.readAsArrayBuffer(file);
      // After reading, read the next file.
    }
  };

  const readerLoaded = async (
    e: ProgressEvent<FileReader>,
    files: File[],
    i: number,
    name: string
  ) => {
    // get file content
    const bin = e.target?.result;
    if (!bin) return;
    // do sth with text
    const fileData = {
      id: i,
      fileName: name,
      file: bin,
    };

    setFileToAdd(fileData);

    // If there's a file left to load
    if (i < files.length - 1 && filesLocal.length <= maxNumberOfFiles) {
      // Load the next file
      setupReader(files, i + 1);
    }
  };

  const handleFileDelete = (fileIndex: number) => {
    const filesDataNew = [...filesLocal];
    if (fileIndex > -1) {
      filesDataNew.splice(fileIndex, 1);
    }
    onChange(filesDataNew);
  };

  const filesRejected = (files: FileRejection[]) => {
    let infoText =
      "Jeden nebo více vložených souborů je moc veliký nebo byl překročen limit počtu souborů.\n\n";
    files.forEach((file) => {
      const fileSize = file.file.size / 1024 / 1024;
      if (fileSize >= 5) {
        infoText += `- ${file.file.name}: ${fileSize.toFixed(2)}MB.\n`;
      }
    });
    infoText += "Maximální povolená velikost souboru je 5MB.";
    alert(infoText);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop: (files) => readmultIFile(files),
    onDropRejected: (files) => filesRejected(files),
    maxSize: 5242880,
    maxFiles: maxNumberOfFiles,
    disabled: disabled,
    // accept: "image/jpeg, image/png",
  });

  return (
    <div>
      <div className="flex flex-row items-center content-start justify-start w-full h-full m-auto">
        <div
          className={`
          flex flex-col justify-around text-center p-3 outline-none border-dashed border-2 rounded-md 
          h-1/2 lg:h-full 
          w-full lg:w-1/2
          mr-0 lg:mr-4 
          mb-4 lg:mb-0
          cursor-pointer         
          border-neutral-textSubtle
          ${isDragActive ? "border-primary-action" : ""}
          ${isDragAccept ? "border-success-action" : ""}
          ${isDragReject ? " border-danger-action" : ""}
          `}
          {...getRootProps()}
        >
          <input {...getInputProps()} disabled={disabled} />
          <p className="text-sm ">
            Přetáhněte požadované <b>soubory</b> <br />
            nebo kliknětě pro výběr.
          </p>
          <p className="text-xs ">Velikost souboru nesmí přesáhnout 5MB.</p>
        </div>
        <ul>
          {filesLocal &&
            filesLocal.length > 0 &&
            filesLocal.map((file, index) => (
              <li className="m-2" key={`${file.fileName}${file.id}${index}`}>
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();

                      handleFileDelete(index);
                    }}
                    className="inline-flex items-center px-2 py-1 mr-2 text-xs font-medium border border-transparent rounded-full shadow-sm text-neutral-textOnDark bg-primary-action hover:bg-primary-actionHover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-actionActive"
                  >
                    <XIcon className="w-3 h-3" />
                  </button>
                  <p className="text-sm ">{file.fileName}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default WSDropzone;
