import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Typography from "@tiptap/extension-typography";
import type { Extensions } from "@tiptap/react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import React from "react";

import { HexColorDecorator } from "./extensions";
import { formatHtml } from "./helpers";
import { Popover } from "./popover";
import { Toolbar } from "./toolbar";

type TiptapProps = {
  onBlur?: () => void;
  onChange: (html: string) => void;
  value?: string;
  error?: boolean;
  isReadOnly?: boolean;
  placeholder?: string;
  withToolbar?: boolean;
  withPopover?: boolean;
  withTypographyExtension?: boolean;
  withLinkExtension?: boolean;
  withTaskListExtension?: boolean;
  withPlaceholderExtension?: boolean;
  withHexColorsDecorator?: boolean;
};

export function WSTextEditor({
  onBlur,
  onChange,
  value = "",
  error = false,
  isReadOnly = false,
  placeholder = "Napište něco...",
  withToolbar = true,
  withPopover = false,
  withTypographyExtension = false,
  withLinkExtension = false,
  withTaskListExtension = false,
  withPlaceholderExtension = false,
  withHexColorsDecorator = false,
}: TiptapProps) {
  const extensions: Extensions = [
    StarterKit,
    Image.configure({
      inline: true,
    }),
  ];

  if (withTypographyExtension) {
    extensions.push(Typography);
  }

  if (withLinkExtension) {
    extensions.push(
      Link.configure({
        linkOnPaste: false,
        openOnClick: false,
      })
    );
  }

  if (withTaskListExtension) {
    extensions.push(TaskList, TaskItem);
  }

  if (withPlaceholderExtension) {
    extensions.push(
      Placeholder.configure({
        placeholder,
      })
    );
  }

  if (withHexColorsDecorator) {
    extensions.push(HexColorDecorator);
  }

  const editor = useEditor({
    editable: !isReadOnly,
    extensions,
    content: value,
    onUpdate: ({ editor }) => {
      //   setEditorHtmlContent(editor.getHTML());
      onChange(formatHtml(editor.getHTML()));
    },
    onBlur: () => onBlur,
    editorProps: {
      attributes: {
        class: "prose prose-sm sm:prose lg:prose-lg my-4 focus:outline-none",
      },
    },
  });

  if (!editor) {
    return null;
  }

  return (
    <div
      className={`p-5 border-[1px] rounded-lg bg-neutral-background border-neutral-border ${
        error && "border-danger-text"
      }`}
    >
      {withToolbar && !isReadOnly ? <Toolbar editor={editor} /> : null}
      {withPopover && !isReadOnly ? <Popover editor={editor} /> : null}
      <EditorContent editor={editor} />
    </div>
  );
}

export default WSTextEditor;
