import chroma from "chroma-js";
import styled from "styled-components";

import getHexColorFromInt from "../../../lib/getHexColorFromInt";

type InputProps = {
  label: string;
  color: string;
};

export const WSTag = ({ label, color }: InputProps) => {
  const localColor = String(color).charAt(0) === "#" ? color : getHexColorFromInt(Number(color));
  const chromaColor = chroma(localColor || "#000000");
  // const textColor = chroma.contrast(chromaColor, "white") > 2.2 ? "white" : "black";

  return (
    <div>
      <STag
        chromaColor={chromaColor}
        className="inline-flex items-center px-3 py-1 text-xs font-medium uppercase rounded-full leading-sm lg:whitespace-nowrap"
      >
        {label}
      </STag>
    </div>
  );
};

const STag = styled.div<{ chromaColor: chroma.Color }>`
  color: ${(p) => p.chromaColor.darken(1).css()};
  background-color: ${(p) => p.chromaColor.brighten(1).alpha(0.1).css()};
  border: ${(p) => `1px solid ${p.chromaColor.brighten(0.4).css()}`};
`;

export default WSTag;
