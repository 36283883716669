import React from "react";

import { WSLoader } from "../loader";

export const WSLoadScreen = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-neutral-background">
      <WSLoader />
    </div>
  );
};
