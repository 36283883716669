export const getHexColorFromInt = (i: number) => {
  // var hex =
  //   (i & 0xff).toString(16) +
  //   ((i >> 8) & 0xff).toString(16) +
  //   ((i >> 16) & 0xff).toString(16) +
  //   ((i >> 24) & 0xff).toString(16);

  // hex += "000000"; // pad result
  // hex = hex.substring(0, 6);
  // return "#" + hex;
  return "#" + ("000000" + (i >>> 0).toString(16)).slice(-6);
};

export default getHexColorFromInt;
