import { WSBox } from "..";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormikState = (props: any) => {
  return (
    <WSBox>
      <div className="overflow-auto max-h-40">
        <h3 className="font-mono" />
        <pre className="text-xs">
          <strong>props</strong> = {JSON.stringify(props, null, 2)}
        </pre>
      </div>
    </WSBox>
  );
};

export default FormikState;
