import React from "react";

import { WSSpinner } from "../spinner";

export const WSLoader = () => {
  return (
    <div className="flex items-center justify-center flex-grow w-full h-full bg-neutral-background">
      <WSSpinner />
      <h1>Počkejte prosím, probíhá načítání dat.</h1>
    </div>
  );
};
