import cs from "date-fns/locale/cs";
import React, { useState } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { WSLabel } from "../label";

registerLocale("cs", cs);

export const WSDateRangePicker = ({
  id,
  name = "",
  isClearable = false,
  showPopperArrow = true,
  placeholderText = "",
  isDisabled = false,
  value = new Date(),
  error,
  touched,
}) => {
  const isLight = true; // you can check what theme you are using right now however you want
  let errorClass = "";
  // const onDateChange = (date) => {
  //   onChange(date);
  // };

  const [startDate, setStartDate] = useState((value && new Date(value)) || new Date());
  const [endDate, setEndDate] = useState(null);
  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  if (touched && error) {
    errorClass = "error";
  } else {
    errorClass = "";
  }

  return (
    // if you don't want to use custom colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div className={`${isLight ? "light-theme" : "dark-theme"} ${errorClass}`}>
      <WSLabel htmlFor={id} label={name} />
      <ReactDatePicker
        id={id}
        selected={startDate}
        onChange={onDateChange}
        startDate={startDate}
        endDate={endDate}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        className="react-datapicker__input-text" // input is white by default and there is no already defined class for it so I created a new one
        // dateFormat={dateFormat}
        selectsRange
        locale="cs"
        placeholderText={placeholderText}
        disabled={isDisabled}
      />
    </div>
  );
};

export default WSDateRangePicker;
