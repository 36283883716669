import { ChangeEvent } from "react";

import { WSLabel } from "../label";

interface IValues {
  id: string | number;
  name: string;
}

type InputProps = {
  id: string;
  name: string;
  placeholder?: string;
  values: IValues[];
  value: string | number;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur: (event: ChangeEvent<HTMLSelectElement>) => void;
  error: string | number | boolean;
  touched: string | number | boolean;
  isDisabled: boolean;
  emptyValue: boolean;
};

export const WSSelect = ({
  id = "",
  name = "noNameSelect",
  values = [],
  onChange,
  value,
  onBlur,
  error,
  touched = false,
  isDisabled,
  emptyValue = true,
}: InputProps) => {
  return (
    <div>
      <WSLabel htmlFor={id} label={name} />
      <select
        id={id}
        name={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={isDisabled}
        autoComplete="off"
        className={`form-select block w-full pl-2 pr-10 py-2 text-base md:text-sm bg-neutral-background border border-neutral-border rounded-lg shadow-sm
            ${
              !isDisabled && error && touched
                ? "border-danger-action text-danger-text placeholder-danger-icon focus:outline-none focus:ring-danger-actionActive"
                : "border-neutral-border focus:ring-primary-actionActive shadow-sm"
            }
             `}
      >
        {" "}
        {emptyValue && <option value=""></option>}
        {values.map((value) => {
          return (
            <option key={value.id} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default WSSelect;
