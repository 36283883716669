import React, { ReactNode } from "react";

import { Provider } from "../utils";

interface ITableInnerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactNode;
}

export const Table = (props: ITableInnerProps) => {
  const { children, ...rest } = props;
  return (
    <Provider value={{}}>
      <table
        className={`w-full relative divide-y divide-neutral-border  responsiveTable`}
        {...rest}
      >
        {children}
      </table>
    </Provider>
  );
};
