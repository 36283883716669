import { BubbleMenu, Editor } from "@tiptap/react";
import React from "react";
import {
  RiBold,
  RiCodeSSlashLine,
  RiH1,
  RiH2,
  RiItalic,
  // RiLink,
  // RiLinkUnlink,
  RiStrikethrough,
} from "react-icons/ri";

// import { setLink } from "./helpers";
import { WSIconButton } from "..";

type PopoverProps = {
  editor: Editor;
};

function Popover({ editor }: PopoverProps) {
  // const isSelectionOverLink = editor.getAttributes("link").href;

  return (
    <BubbleMenu className="flex gap-1 p-1 rounded-lg bg-neutral-shortcutBackground" editor={editor}>
      <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() => editor.chain().focus().toggleBold().run()}
      >
        <RiBold />
      </WSIconButton>
      <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() => editor.chain().focus().toggleItalic().run()}
      >
        <RiItalic />
      </WSIconButton>
      <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() => editor.chain().focus().toggleStrike().run()}
      >
        <RiStrikethrough />
      </WSIconButton>
      <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
      >
        <RiH1 />
      </WSIconButton>
      <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
      >
        <RiH2 />
      </WSIconButton>
      {/* <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() =>
          isSelectionOverLink ? editor.chain().focus().unsetLink().run() : setLink(editor)
        }
      >
        {isSelectionOverLink ? <RiLinkUnlink /> : <RiLink />}
      </WSIconButton> */}
      <WSIconButton
        color="secondary"
        dimensions="small"
        onClick={() => editor.chain().focus().toggleCode().run()}
      >
        <RiCodeSSlashLine />
      </WSIconButton>
    </BubbleMenu>
  );
}

export { Popover };
