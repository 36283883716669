import PropTypes from "prop-types";
import React from "react";

import GlobalFilter from "./GlobalFilter";

const TableToolbar = ({ preGlobalFilteredRows, setGlobalFilter, globalFilter }) => {
  return (
    <GlobalFilter
      preGlobalFilteredRows={preGlobalFilteredRows}
      globalFilter={globalFilter}
      setGlobalFilter={setGlobalFilter}
    />
  );
};

TableToolbar.propTypes = {
  setGlobalFilter: PropTypes.func.isRequired,
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
};

export default TableToolbar;
