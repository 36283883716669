import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import React from "react";

import { WSSelect } from "@winshop-crm/ui";

const TablePagination = (props) => {
  const {
    canPreviousPage,
    previousPage,
    pageIndex,
    pageOptions,
    pageSize,
    nextPage,
    canNextPage,
    setPageSize,
    gotoPage,
    pageCount,
  } = props;

  const pageSizes = [
    { id: 10, name: "Zobrazit 10 záznamů" },
    { id: 20, name: "Zobrazit 20 záznamů" },
    { id: 30, name: "Zobrazit 30 záznamů" },
    { id: 40, name: "Zobrazit 40 záznamů" },
    { id: 50, name: "Zobrazit 50 záznamů" },
  ];

  return (
    <div className="flex items-center justify-center w-full pt-3 pb-6 bg-neutral-backgroundSubtle ">
      <nav className="flex items-center justify-between w-5/6 lg:w-3/5 sm:px-0">
        <div className="flex flex-1 w-0 -mt-px">
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            aria-label="První stránka"
            className="inline-flex items-center p-2 mr-2 rounded-md bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:outline-none"
          >
            <ChevronDoubleLeftIcon className="w-5 h-5 text-neutral-textSubtle" aria-hidden="true" />
          </button>
          <button
            onClick={previousPage}
            disabled={!canPreviousPage}
            aria-label="Předchozí stránka"
            className="inline-flex items-center p-2 rounded-md bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:outline-none"
          >
            <ChevronLeftIcon className="w-5 h-5 text-neutral-textSubtle" aria-hidden="true" />
          </button>
        </div>
        <div className="items-center hidden md:-mt-px md:flex">
          <p className="mr-6">
            Strana <span className="font-bold">{pageIndex + 1}</span> z{" "}
            <span className="font-bold">{pageOptions.length}</span>
          </p>
          <WSSelect
            id="pageSizes"
            name=""
            values={pageSizes}
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            emptyValue={false}
          />
        </div>
        <div className="flex justify-end flex-1 w-0 -mt-px">
          <button
            onClick={nextPage}
            disabled={!canNextPage}
            aria-label="Další stránka"
            className="inline-flex items-center p-2 rounded-md bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:outline-none"
          >
            <ChevronRightIcon className="w-5 h-5 text-neutral-textSubtle " aria-hidden="true" />
          </button>
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
            aria-label="Poslední stránka"
            className="inline-flex items-center p-2 ml-2 rounded-md bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:outline-none"
          >
            <ChevronDoubleRightIcon
              className="w-5 h-5 text-neutral-textSubtle"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
    </div>
  );
};

export default TablePagination;
