import React, { ReactNode } from "react";

interface ITbodyInnerProps {
  columnkey?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Record<number, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactNode;
}

export const Tbody = (props: ITbodyInnerProps) => (
  <tbody className="divide-y divide-neutral-separatorSubtle" {...props} />
);
