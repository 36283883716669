import React, { ReactElement } from "react";

import { Consumer } from "../utils";

interface ITrInnerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: Record<number, any>;
  inHeader?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: Record<string, any>;
}

const TrInner = (props: ITrInnerProps) => {
  const { headers, children, inHeader, ...rest } = props;

  if (headers && inHeader) {
    React.Children.map(props.children, (child, i) => {
      if (child) {
        headers[i] = (child as ReactElement).props.children;
      }
    });
  }

  const childProps = (idx: number) => (inHeader ? { key: idx } : { key: idx, columnkey: idx });

  return (
    <tr className="font-light cursor-pointer lg:hover:bg-neutral-hover" {...rest}>
      {children &&
        React.Children.map(
          children,
          (child, idx) => React.isValidElement(child) && React.cloneElement(child, childProps(idx))
        )}
    </tr>
  );
};

export type ITrProps = Omit<ITrInnerProps, "headers">;

export const Tr = (props: ITrProps) => (
  <Consumer>{(headers) => <TrInner {...props} headers={headers} />}</Consumer>
);
