import { useEffect, useState } from "react";

export const WSAvatar = ({
  userName,
  src,
  size,
}: {
  userName: string;
  src: string;
  size: "sm" | "md" | "lg" | "xl";
}) => {
  const [userInitials, setUserInitials] = useState("");

  useEffect(() => {
    if (userName) {
      setUserInitials(
        userName
          .split(" ")
          .map((n) => n[0])
          .join("")
      );
    }
  }, [userName]);

  return (
    <>
      {src && src != "data:image/png;base64," ? (
        // eslint-disable-next-line @next/next/no-img-element
        <img
          className={`
        ${size == "sm" ? "h-8 w-8" : ""}
        ${size == "md" ? "h-10 w-10" : ""}
        ${size == "lg" ? "h-12 w-12" : ""}
        ${size == "xl" ? "h-20 w-20" : ""}

        rounded-full`}
          src={src}
          alt=""
        />
      ) : (
        <span
          className={`
        inline-flex items-center justify-center 
        ${size == "sm" ? "h-8 w-8 text-sm" : ""}
        ${size == "md" ? "h-10 w-10 text-sm" : ""}
        ${size == "lg" ? "h-12 w-12 text-lg" : ""}
        ${size == "xl" ? "h-20 w-20 text-xl" : ""}
        rounded-full bg-neutral-shortcutBackground`}
        >
          <span className="font-medium leading-none text-neutral-textOnDark">{userInitials}</span>
        </span>
      )}
    </>
  );
};

export default WSAvatar;
