/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from "react";

interface IThInnerProps {
  columnkey?: number;
  narrowHeaders: Record<number, any>;
  columns: Record<number, any>;
  children: ReactNode;
}

export const Th = (props: IThInnerProps) => (
  <th
    className="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left uppercase text-neutral-textSubtle bg-neutral-backgroundSubtle"
    {...props}
  >
    {props.children}
  </th>
);
