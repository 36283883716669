import React, { ReactNode } from "react";

interface ITheadInnerProps {
  columnkey?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  narrowHeaders: Record<number, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: Record<number, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactNode;
}

export const Thead = (props: ITheadInnerProps) => {
  const { children, ...rest } = props;

  return (
    <thead className="sticky top-0 left-0 table-header-group" {...rest}>
      {/* {React.isValidElement(children) &&
        React.cloneElement(children, { inHeader: true })} */}
      {children}
    </thead>
  );
};
