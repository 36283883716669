import { PaperClipIcon, XIcon } from "@heroicons/react/outline";
import { saveAs } from "file-saver";
import React, { MouseEvent } from "react";

type InputProps = {
  fileName: string;
  file: WithImplicitCoercion<string>;
  onDelete?: () => void;
};

export const WSFile = ({ fileName, file, onDelete }: InputProps) => {
  const handleFileDownload = (fileName: string, file: WithImplicitCoercion<string>) => {
    // eslint-disable-next-line new-cap
    const buffer = Buffer.from(file, "base64");
    const blob = new Blob([buffer]);
    // downloadBlob(blob, fileName);
    saveAs(blob, fileName);
  };

  const handleDelete = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    if (onDelete) {
      onDelete();
    }
  };

  return (
    <div
      className="relative inline-flex items-center px-3 py-1 my-1 text-sm border rounded-full cursor-pointer bg-neutral-background border-primary-backgroundSubtle"
      onClick={() => handleFileDownload(fileName, file)}
    >
      <PaperClipIcon className="w-3 h-3 mr-1" />
      <span className="text-xs text-neutral-text">{fileName}</span>
      {onDelete && (
        <button
          onClick={(e) => handleDelete(e)}
          className="p-1 ml-2 -mr-2 rounded-full text-danger-action bg-neutral-backgroundSubtle focus:outline-none hover:bg-danger-actionHover"
        >
          <XIcon className="w-2 h-2" />
        </button>
      )}
    </div>
  );
};

export default WSFile;
