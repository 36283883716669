import { SortAscendingIcon, SortDescendingIcon } from "@heroicons/react/outline";
import * as React from "react";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import styled from "styled-components";

import { Table, Tbody, Td, Th, Thead, Tr } from "./table";
import TablePagination from "./TablePagination";
import TableToolbar from "./TableToolbar";

export const EnhancedTable = ({ columns, data, handleRowClick }) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    preGlobalFilteredRows,
    setGlobalFilter,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 30 },
    },
    useGlobalFilter,
    useSortBy,
    // useFlexLayout,
    usePagination
  );
  // Render the UI for your table
  return (
    <StyledWrapper className="flex flex-col items-center justify-start w-full h-full">
      <div className="w-full h-20 shrink ">
        <TableToolbar
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter || ""}
        />
      </div>
      <div className="flex-grow w-full overflow-y-auto">
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    key={column.id}
                    {...(column.id === "selection"
                      ? column.getHeaderProps()
                      : column.getHeaderProps(column.getSortByToggleProps()))}
                  >
                    {column.render("Header")}
                    <span pl="4">
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SortDescendingIcon className="w-4 h-4" aria-label="sorted descending" />
                        ) : (
                          <SortAscendingIcon className="w-4 h-4" aria-label="sorted ascending" />
                        )
                      ) : null}
                    </span>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  key={row.original.id}
                  onClick={(event) => {
                    handleRowClick(event, row);
                  }}
                  onAuxClick={(event) => {
                    handleRowClick(event, row);
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td
                        narrowHeaders={columns}
                        columns={columns}
                        key={cell.id}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
      <div className="shrink w-full h-[81px]">
        <TablePagination
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          pageSize={pageSize}
          nextPage={nextPage}
          canNextPage={canNextPage}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          pageCount={pageCount}
        />
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: calc(100vh - 120px);
`;

export default EnhancedTable;
