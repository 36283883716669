import React, { FC, HTMLProps, PropsWithChildren, ReactNode } from "react";
import styled from "styled-components";

type SProps = {
  size?: "small" | "medium" | "large";
  variant?: "full";
  color?: "primary" | "secondary" | "danger" | "neutralDanger";
};

export type WSButtonProps = PropsWithChildren<
  SProps &
    Omit<HTMLProps<HTMLButtonElement>, "size"> & {
      startIcon?: ReactNode;
      endIcon?: ReactNode;
    }
>;

const SIcon = styled.span<{ position: "start" | "end" }>`
  display: inline-flex;
  > * {
    margin: auto;
  }
  margin-right: ${(p) => p.position === "start" && `0.5rem`};
  margin-left: ${(p) => p.position === "end" && `0.5rem`};
`;

const SButton = styled.button<SProps>`` as FC<WSButtonProps>;

export const WSButton = ({
  size,
  variant,
  color,
  startIcon,
  endIcon,
  children,
  ...props
}: WSButtonProps) => {
  return (
    <SButton
      className={`
            relative inline-flex items-center justify-center px-4 py-2 
            border border-transparent rounded-md shadow-sm
            focus:outline-none focus:ring-2 focus:ring-offset-2 
            disabled:opacity-50 
            ${size === "small" ? "text-xs font-medium px-2 py-1" : ""}
            ${size === "medium" ? "text-sm font-medium " : ""}
            ${size === "large" ? "text-md font-semibold " : ""}
            ${variant === "full" ? "w-full" : ""}
            ${
              color === "primary"
                ? "text-neutral-textOnPrimary bg-primary-action hover:bg-primary-actionHover focus:ring-primary-actionHover"
                : ""
            }            
            ${
              color === "secondary"
                ? "text-neutral-text bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:ring-neutral-actionSecondaryHover"
                : ""
            }
            ${
              color === "danger"
                ? "text-neutral-textOnDark bg-danger-action hover:bg-danger-actionHover focus:bg-danger-actionHover"
                : ""
            }            
            ${
              color === "neutralDanger"
                ? "text-danger-text bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:ring-neutral-actionSecondaryHover"
                : ""
            }            
        `}
      {...props}
    >
      {startIcon && <SIcon position="start">{startIcon}</SIcon>}
      {children}
      {endIcon && <SIcon position="end">{endIcon}</SIcon>}
    </SButton>
  );
};

export default WSButton;
