import { ChangeEvent } from "react";

type InputProps = {
  id: string;
  checked: boolean;
  label: string;
  description?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const WSCheckbox = ({
  id,
  checked,
  label: text,
  onChange,
  onBlur,
  description,
}: InputProps) => {
  return (
    <div className="flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          aria-describedby="comments-description"
          name={id}
          type="checkbox"
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          className="w-4 h-4 rounded text-primary-text border-neutral-border focus:ring-primary-actionActive"
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor={id} className="font-medium text-neutral-textSubtle">
          {text}
        </label>
        {description && (
          <p id="comments-description" className="font-light text-neutral-textSubtle">
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default WSCheckbox;
