import React, { useEffect, useState } from "react";

// import WinShopLogo from "./WinShopLogo";
import WinShopLogoBeta from "./WinShopLogoBeta";

export type WinShopLogoProps = {
  blueLogo?: boolean;
  className?: string;
};

export function WinShopLogo({ blueLogo = true, className = "" }: WinShopLogoProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <div>
      {isLoaded && (
        <WinShopLogoBeta
          color={blueLogo ? "#075E9D" : "#ffffff"}
          className={`width-auto h-8 mx-auto ${className}`}
        />
      )}
    </div>
  );
}

export default WinShopLogo;
