import { SearchIcon } from "@heroicons/react/outline";
import PropTypes from "prop-types";
import React from "react";
const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  // const count = preGlobalFilteredRows.length;

  // Global filter only works with pagination from the first page.
  // This may not be a problem for server side pagination when
  // only the current page is downloaded.

  return (
    <div className="relative mx-4 my-4 rounded-md shadow-sm md:mx-0">
      <input
        type="text"
        name="account_number"
        id="account_number"
        value={globalFilter || ""}
        placeholder="Vyhledat v zobrazených"
        onChange={(e) => {
          setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        className="block w-full pr-10 rounded-md text-neutral-textSubtle placeholder-neutral-textSubtle form-input focus:ring-primary-actionActive focus:border-primary-actionActive sm:text-sm border-neutral-border"
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <SearchIcon className="w-5 h-5 text-neutral-textSubtle" aria-hidden="true" />
      </div>
    </div>
  );
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.array.isRequired,
  globalFilter: PropTypes.string.isRequired,
  setGlobalFilter: PropTypes.func.isRequired,
};

export default GlobalFilter;
