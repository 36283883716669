import { Editor } from "@tiptap/react";
import React from "react";
import { useInView } from "react-cool-inview";
import {
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiBold,
  RiCodeBoxLine,
  RiCodeSSlashLine,
  RiDoubleQuotesL,
  RiFormatClear,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiImageFill,
  RiItalic,
  // RiLink,
  // RiLinkUnlink,
  RiListOrdered,
  RiListUnordered,
  RiParagraph,
  RiSeparator,
  RiStrikethrough,
  RiTextWrap,
} from "react-icons/ri";

// import { setLink } from "./helpers";
import { WSIconButton } from "..";

type ToolbarProps = {
  editor: Editor;
};

function Toolbar({ editor }: ToolbarProps) {
  // const isCursorOverLink = editor.getAttributes("link").href;

  const { observe, inView } = useInView({
    rootMargin: "-1px 0px 0px 0px",
    threshold: [1],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.target?.files?.[0]) return;

    const file = e.target.files[0];
    if (file.size > 1000000 || !file.type.includes("image")) {
      alert("Vybraný soubor je moc velký nebo se nejedná o obrázek");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (!reader?.result) return;
      addImage(reader.result.toString());
    };
    reader.onerror = function (error) {
      alert(`Chyba při vkládání obrázku\n${error}`);
    };
  };

  // Adding image to the editor
  const addImage = (url: string) => {
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  };

  return (
    <div
      className={`${!inView && "border-t shadow-md rounded-t-none"} sticky top-0 z-[1]
      border-b-2 flex justify-start items-center min-h-10 rounded-t-lg -mt-5 -mx-5 bg-neutral-background border-neutral-border`}
      ref={observe}
    >
      <div className="flex flex-wrap gap-1 p-3">
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleBold().run()}>
          <RiBold />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleItalic().run()}>
          <RiItalic />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleStrike().run()}>
          <RiStrikethrough />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleCode().run()}>
          <RiCodeSSlashLine />
        </WSIconButton>
        <Divider />
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        >
          <RiH1 />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        >
          <RiH2 />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        >
          <RiH3 />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        >
          <RiH4 />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        >
          <RiH5 />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        >
          <RiH6 />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().setParagraph().run()}>
          <RiParagraph />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleBulletList().run()}>
          <RiListOrdered />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <RiListUnordered />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleCodeBlock().run()}>
          <RiCodeBoxLine />
        </WSIconButton>
        {/* <Divider />
        <WSIconButton color="white" onClick={() => setLink(editor)}>
          <RiLink />
        </WSIconButton>
        <WSIconButton color="white" disabled={!isCursorOverLink} onClick={() => setLink(editor)}>
          <RiLinkUnlink />
        </WSIconButton> */}
        <Divider />
        <WSIconButton color="white" onClick={() => editor.chain().focus().toggleBlockquote().run()}>
          <RiDoubleQuotesL />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <RiSeparator />
        </WSIconButton>
        <Divider />
        <WSIconButton color="white" onClick={() => editor.chain().focus().setHardBreak().run()}>
          <RiTextWrap />
        </WSIconButton>
        <WSIconButton
          color="white"
          onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
        >
          <RiFormatClear />
        </WSIconButton>
        <div className="divider"></div>
        <WSIconButton color="white" onClick={() => editor.chain().focus().undo().run()}>
          <RiArrowGoBackLine />
        </WSIconButton>
        <WSIconButton color="white" onClick={() => editor.chain().focus().redo().run()}>
          <RiArrowGoForwardLine />
        </WSIconButton>
        <Divider />
        <WSIconButton color="white">
          <label className="inline-block cursor-pointer" htmlFor="upload">
            <RiImageFill aria-label="image" className="w-4 h-4" />
            <input className="hidden" id="upload" type="file" onChange={handleChange} />
          </label>
        </WSIconButton>
      </div>
    </div>
  );
}

const Divider = () => {
  return <div className="mx-2 border-l-2 border-neutral-border" />;
};

export { Toolbar };
