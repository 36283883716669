import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import React, { KeyboardEvent, useCallback, useRef, useState } from "react";

import { WSIconButton } from "@winshop-crm/ui";

type InputProps = {
  id: string;
  placeholder?: string;
  prefix?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  error: string | number | boolean;
  touched: string | number | boolean;
  isReadOnly: boolean;
};

export const WSEditableText = ({
  id,
  placeholder,
  prefix,
  value,
  onChange,
  error,
  touched,
  isReadOnly,
}: InputProps) => {
  const [editing, setEditing] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const displayTextRef = useRef(null);

  const focusOnTextInput = useCallback(() => {
    setEditing(!editing);
    setDisplayText(value);
    /* 
         A little hack to wait event-loop to flush-out itself
         The issue is, when the user clicked on the text 
         or the edit button, the focus instantly being given
         to the input element. But, it`s not visible at the moment.
         By calling the `setTimeout`, function call will be done
         after the event-loop has executed all the functions.
      */
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [editing]);

  const updateDisplayText = useCallback(() => {
    const value = inputRef?.current?.value;
    value && setDisplayText(value);
  }, []);

  const terminateEditing = useCallback(() => {
    setEditing(false);
  }, []);

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter" && value !== inputRef.current?.value) {
      handleSaveText();
    } else if (e.key == "Esc") {
      terminateEditing();
    }
  };

  const handleSaveText = useCallback(() => {
    terminateEditing();
    const value = inputRef?.current?.value;
    value && onChange(value);
  }, []);

  return (
    <div
      className={`w-full flex items-center content-center relative text-lg
        ${
          !isReadOnly && error && touched
            ? "border-danger-action text-danger-text placeholder-danger-icon focus:outline-none focus:ring-danger-actionActive"
            : ""
        }
      ${!error ? "focus:ring-primary-actionActive " : ""}
    `}
    >
      {prefix && <h1 className="ml-2 mr-1 whitespace-nowrap">{prefix}</h1>}
      {!isReadOnly && (
        <input
          id={id}
          type="text"
          onKeyPress={handleKeyPress}
          className={`form-input block w-full p-0 border-none pr-10 outline-none bg-transparent rounded-lg form-input focus:ring-0 pl-1
        ${editing ? "" : "hidden"}      
        `}
          ref={inputRef}
          placeholder={placeholder}
          value={displayText}
          onChange={updateDisplayText}
        />
      )}
      <span
        ref={displayTextRef}
        className={`mr-1 ${!isReadOnly ? "cursor-pointer" : ""} flex items-center ${
          !editing ? "" : "hidden"
        }`}
        onClick={(!isReadOnly && focusOnTextInput) || undefined}
      >
        {value}
      </span>
      {!isReadOnly && (
        <>
          <WSIconButton
            color="secondary"
            onClick={focusOnTextInput}
            className={`ml-2 ${!editing ? "" : "hidden"}`}
          >
            <PencilIcon className="w-4 h-4" />
          </WSIconButton>
          <WSIconButton
            color="secondary"
            onClick={handleSaveText}
            className={`ml-2 ${editing ? "" : "hidden"}`}
          >
            <CheckIcon className="w-4 h-4" />
          </WSIconButton>
          <WSIconButton
            color="secondary"
            onClick={terminateEditing}
            className={`ml-2 ${editing ? "" : "hidden"}`}
          >
            <XIcon className="w-4 h-4" />
          </WSIconButton>
        </>
      )}
    </div>
  );
};

export default WSEditableText;
