import React, { HTMLProps, MouseEvent, ReactNode } from "react";

interface SProps {
  variant?: "square" | "circle";
  color: "primary" | "secondary" | "danger" | "neutralDanger" | "white";
  dimensions?: "small" | "medium" | "large";
  children: ReactNode;
  className?: string;
}

type WSIconButtonProps = HTMLProps<HTMLButtonElement> & SProps;

export const WSIconButton = ({
  dimensions,
  color,
  children,
  className,
  ...props
}: WSIconButtonProps) => {
  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    props.onClick?.(e);
  };
  return (
    <button
      {...props}
      type="button"
      onClick={onClick}
      className={`
            relative inline-flex items-center p-1 
            border border-transparent rounded-md shadow-md
            focus:outline-none focus:ring-2 focus:ring-offset-2 
            disabled:opacity-50 disabled:cursor-not-allowed
            ${dimensions === "small" ? "text-xs font-medium px-1 py-1" : ""}
            ${dimensions === "medium" ? "text-sm font-medium " : ""}
            ${dimensions === "large" ? "text-md font-semibold " : ""}
            ${
              color === "primary"
                ? "text-neutral-textOnPrimary bg-primary-action hover:bg-primary-actionHover focus:ring-primary-actionHover"
                : ""
            }            
            ${
              color === "secondary"
                ? "text-neutral-text bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:ring-neutral-actionSecondaryHover"
                : ""
            }
            ${
              color === "white"
                ? "text-neutral-text bg-neutral-background shadow-none hover:text-neutral-background hover:shadow-md hover:bg-neutral-actionSecondaryHover focus:ring-neutral-actionSecondaryHover"
                : ""
            }
            ${
              color === "danger"
                ? "text-neutral-textOnDark bg-danger-action hover:bg-danger-actionHover focus:bg-danger-actionHover"
                : ""
            }            
            ${
              color === "neutralDanger"
                ? "text-danger-text bg-neutral-actionSecondary hover:bg-neutral-actionSecondaryHover focus:ring-neutral-actionSecondaryHover"
                : ""
            }   
            ${className}         
        `}
    >
      {children}
    </button>
  );
};

export default WSIconButton;
