import React, { PropsWithChildren } from "react";

type WSBoxProps = PropsWithChildren<{
  component?: string | React.ComponentType<unknown>;
}>;

export const WSBox = (props: WSBoxProps) => {
  return (
    <div className="my-2 overflow-visible shadow-lg bg-neutral-background lg:rounded-lg">
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  );
};

export default WSBox;
