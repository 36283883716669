import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import cs from "date-fns/locale/cs";
import React from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";

import { WSLabel } from "../label";
registerLocale("cs", cs);

export const WSDatePicker = ({
  id,
  name = "",
  showTimeSelect = true,
  isClearable = false,
  showPopperArrow = true,
  placeholderText = "",
  dateFormat = "dd. mm. yyyy - hh:mm",
  isDisabled = false,
  value,
  onChange,
  error,
  touched,
  ...props
}) => {
  const isLight = true; // you can check what theme you are using right now however you want
  let errorClass = "";
  const onDateChange = (date) => {
    onChange(date);
  };

  if (touched && error) {
    errorClass = "error";
  } else {
    errorClass = "";
  }

  return (
    // if you don't want to use custom colors or you just wwant to use the original ones,
    // set className to "light-theme-original" ↓↓↓↓
    <div
      className={`${isLight ? "light-theme" : "dark-theme"} ${errorClass} ${
        showTimeSelect ? "170" : "160"
      }`}
    >
      <WSLabel htmlFor={id} label={name} />
      <ReactDatePicker
        id={id}
        {...props}
        selected={value ? new Date(value) : ""}
        onChange={onDateChange}
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        // className="react-datapicker__input-text" // input is white by default and there is no already defined class for it so I created a new one
        timeIntervals={15}
        showTimeSelect={showTimeSelect}
        showTime={showTimeSelect}
        dateFormat={dateFormat}
        timeFormat="HH:mm"
        locale="cs"
        placeholderText={placeholderText}
        timeCaption="Čas"
        disabled={isDisabled}
        autoComplete="off"
        nextMonthButtonLabel={<ChevronRightIcon className="w-4 h-4" />}
        previousMonthButtonLabel={<ChevronLeftIcon className="w-4 h-4" />}
      />
    </div>
  );
};

export default WSDatePicker;
