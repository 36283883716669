import chroma from "chroma-js";
import Select from "react-select";

import MenuList from "./WSSelectAutocompleteMenuList";
import { WSLabel } from "../label";

export const WSSelectAutocomplete = ({
  id = "",
  name = "noNameSelect",
  placeholder = "",
  values = [],
  onChange,
  value,
  onBlur,
  error,
  touched,
  isDisabled,
}) => {
  const isLight = true;
  const background = `${isLight ? "white" : "rgba(17, 24, 39, 1"}`;
  const color = `${isLight ? "rgba(31, 41, 55, 1)" : "rgba(255, 255, 255, 0.7)"}`;
  const backgroundHover = `${isLight ? "#a5a5a590" : "#ffffffc3"}`;
  const backgroundSelected = `${isLight ? "#E2E8F0" : "#2D3748"}`;

  let borderColor = `${isLight ? "rgba(209, 213, 219, 1)" : "rgba(209, 213, 219, 1)"}`;
  const borderSize = "1px solid";
  const borderRadius = "0.375rem";

  if (touched && error) {
    borderColor = "rgba(252, 165, 165, 1)";
  } else {
    borderColor = `${isLight ? "rgba(209, 213, 219, 1)" : "rgba(209, 213, 219, 1)"}`;
  }

  const customStyles = {
    container: (styles) => {
      return {
        ...styles,
        transition: "all 0.2s",
        borderRadius: borderRadius,
        border: borderSize,
        borderColor: borderColor,
        boxShadow: "0 0 rgba(0,0,0,0), 0 0 rgba(0,0,0,0), 0 1px 2px 0 rgba(0, 0, 0, 0.05)",
        lineHeight: "1.25rem",
        fontSize: "0.875rem",
        margin: "0px",
        padding: "0px",
        zIndex: "10",
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        // height: "32px",
        margin: "0",
        fontSize: "0.875rem",
      };
    },
    input: (styles) => {
      return {
        ...styles,
        border: "none",
        outline: "none",
      };
    },
    indicatorsContainer: (styles) => {
      return {
        ...styles,
        // height: "32px",
        padding: "1px",
      };
    },
    singleValue: (styles) => {
      return { ...styles, color: color };
    },
    control: (styles) => {
      return {
        ...styles,
        border: "none",
        background: background,
        borderRadius: borderRadius,
        fontSize: "0.875rem",
        color: color,
        margin: "0",
        padding: "0px",
        minHeight: "30px",
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        minHeight: "34px",
        background: background,
        borderRadius: borderRadius,
        padding: "4px",
        "*::-webkit-scrollbar": {
          width: "12px",
        },
        "*::-webkit-scrollbar-track": {
          margin: "6px",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.1)",
          pointer: "cursor",
          borderRadius: borderRadius,
        },
      };
    },
    menuList: (styles) => {
      return {
        ...styles,
        minHeight: "34px",
        background: background,
        borderRadius: borderRadius,
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        padding: "6px 10px",
        borderRadius: borderRadius,
        width: "99%",
        margin: "auto",
        backgroundColor: isSelected ? backgroundSelected : isFocused ? backgroundHover : null,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? chroma.contrast(backgroundSelected, "white") > 2
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
          backgroundColor: isSelected ? "pink" : "transparent",
        },
      };
    },
  };

  const handleChange = (value) => {
    // this is going to call setFieldValue and manually update
    if (value != null) {
      onChange(id, value.value || 0);
    } else {
      onChange(id, 0);
    }
  };
  const handleBlur = () => {
    // this is going to call setFieldTouched and manually update
    onBlur(id, true);
  };

  const localValue = values.filter((item) => item.value === value);

  return (
    <div>
      <WSLabel htmlFor={id} label={name} />
      <Select
        size="sm"
        id={id}
        onChange={handleChange}
        onBlur={handleBlur}
        value={localValue}
        components={{ MenuList }}
        styles={customStyles}
        options={values}
        closeMenuOnScroll={true}
        placeholder={placeholder}
        backspaceRemovesValue={true}
        isClearable={true}
        menuPlacement="auto"
        isDisabled={isDisabled}
      />
    </div>
  );
};
