import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { ChangeEvent } from "react";

import { WSLabel } from "../label";

type InputProps = {
  id: string;
  name: string;
  placeholder?: string;
  value: string | number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: ChangeEvent<HTMLInputElement>) => void;
  error: string | number | boolean;
  touched: string | number | boolean;
  isReadOnly: boolean;
  type: "email" | "number" | "password" | "tel" | "text" | "url";
  autoComplete: "on" | "off";
};

export const WSTextInput = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  touched = false,
  isReadOnly,
  type = "text",
  autoComplete = "off",
}: InputProps) => {
  return (
    <div>
      <WSLabel htmlFor={id} label={name} />
      <div className="relative mt-1">
        <input
          type={type}
          autoComplete={autoComplete}
          name={id}
          id={id}
          placeholder={placeholder}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={isReadOnly}
          className={`form-input block w-full pl-3 pr-2 py-2 text-base md:text-sm bg-neutral-background border border-neutral-border rounded-lg shadow-sm
            ${
              !isReadOnly && error && touched
                ? "border-danger-action text-danger-text placeholder-danger-icon focus:outline-none focus:ring-danger-actionActive"
                : "border-neutral-border focus:ring-primary-actionActive shadow-sm"
            }
            `}
          aria-invalid="true"
        />
        {!isReadOnly && error && touched && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon className="w-5 h-5 text-danger-text" aria-hidden="true" />
          </div>
        )}
      </div>
    </div>
  );
};

export default WSTextInput;
